import React, { Component } from "react";

import { Form, Input, Button, Typography } from "antd";

import { ThingsBoardIntegration } from "@chirpstack/chirpstack-api-grpc-web/api/application_pb";

interface IProps {
  initialValues: ThingsBoardIntegration;
  onFinish: (obj: ThingsBoardIntegration) => void;
}

class ThingsBoardIntegrationForm extends Component<IProps> {
  onFinish = (values: ThingsBoardIntegration.AsObject) => {
    const v = Object.assign(this.props.initialValues.toObject(), values);
    let i = new ThingsBoardIntegration();

    i.setApplicationId(v.applicationId);
    i.setServer(v.server);

    this.props.onFinish(i);
  };

  render() {
    return (
      <Form layout="vertical" initialValues={this.props.initialValues.toObject()} onFinish={this.onFinish}>
        <Form.Item
          label="ThingsBoard server"
          name="server"
          rules={[{ required: true, message: "Please enter the address to the ThingsBoard server!" }]}
        >
          <Input placeholder="http://host:port" />
        </Form.Item>
        <Form.Item>
          <Typography.Paragraph>
            Each device must have a 'ThingsBoardAccessToken' variable assigned. This access-token is generated by
            ThingsBoard.
          </Typography.Paragraph>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default ThingsBoardIntegrationForm;
